.License {
    padding: 10px;
    text-align: center;
    background-color: rgb(240, 240, 240);
    min-height: 100vh;
}

.LicenseTitle {
    font-size: 50px;
}

.LicenseDescription {
    font-size: 20px;
}

.LicenseLinkParent {
    margin: 10px;
}

.back-link-top {
    padding-left: 15vh;
    color: rgb(155, 149, 149);
    font-size: 25px;
    height: 10vh;
}