.DBTabContents {
  margin: 5px;
  padding: 8px;
}

.DBSettingModal {
  width: 60%;
  max-width: 80%;
  min-width: 1200px;
}

.dbSettingBody {
  background-color: rgb(200, 200, 200);
  border-radius: 5px;
}

.DBSettingTitle {
  margin: 5px;
}

/* テーブルヘッダー */
.SettingTableColumnHeader, .sliderComponents {
  background-color: rgb(245, 245, 245);
}

.saveButtonDiv {
  text-align: center;
  margin: 10px;
}

.SettingDataGrid, .MuiDataGrid-virtualScroller{
  background-color: rgb(255, 255, 255);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 500;
}

.minMaxHeader{
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 500;
}

.sliderComponents .MuiDataGrid-columnHeaderTitleContainerContent{
  width: 100%;
  margin-right: 24px;
}
