.SliderClass {
  margin-left: 20px;
}

.ConditionNumber {
  margin-left: 50px;
  font-size: 20px;
}

.SliderBox {
  width: 100%;
}