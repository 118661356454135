.informationTab {
  padding: 5px 20px;
  font-size: 1.15rem;
}

.informationTabDisabled {
  font-size: 20px;
  color: rgb(255, 255, 255);
  padding: 10px 30px;
  background-color: rgb(130, 180, 250);
  border-radius: 30px;
}
