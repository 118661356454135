.AuthClass {
  margin-bottom: 50px;
}

.InputUserDataAlert {
  margin: 0px 25px 0px;
}

.InputUserDataAlertContents {
  padding: 0px 12px 0px;
}

.PersonalInfoLink {
  margin: -10px 0px -10px 10px;
  text-align: center;
}

.TosLink {
  margin: -10px 0px 10px 10px;
  text-align: center;
}

.PersonalInfoAlert {
  margin: 0px 10px 0px;
}

.AgreementCheckBox {
  margin: -10px 0px -10px 10px;
}


.LoginCheckBoxView {
  text-align: center;
}

.SigninHeader, .SignupHeader {
  padding: 10px;
  text-align: center;
}

.amplify-tabs-item, .amplify-tabs-item[data-state=active] {
  font-weight: 500;
  font-style: initial;
  font-size: initial;
}

.amplify-button--primary {
  height: 40px;
  border-radius: 5px;
  color: rgb(50, 50, 50);
  font-weight: 500!important;
  font-style: initial;
  font-size: initial;
  border: solid 1px rgb(100, 100, 100);
  box-shadow: 0px 0px 4px rgb(128, 128, 128);
  background-color: rgb(250, 250, 250);
}

.amplify-button--primary:hover {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border: solid 1px rgb(150, 150, 150);
  box-shadow: 0px 0px 4px rgb(0, 132, 255);
}

.amplify-button--primary:disabled {
  color: rgb(150, 150, 150);
  border: solid 1px rgb(100, 100, 100);
  box-shadow: 0px 0px 4px rgb(128, 128, 128);
  background-color: rgb(200, 200, 200);
  cursor: no-drop;
}

.amplify-button--small {
  font-weight: 500;
  border: none;
  background-color: inherit;
  text-decoration: underline;
  color: rgb(13, 110, 253);
}

.amplify-button--small:hover {
  border: none;
  background-color: inherit;
  text-decoration: underline;
  color: rgb(0, 63, 157);
}

.amplify-button--small:active {
  border: none!important;
  background-color: inherit;
  text-decoration: underline;
  color: rgb(0, 63, 157);
}

.ResetPasswordButton {
  border: none;
  background-color: inherit;
  text-decoration: underline;
  color: rgb(13, 110, 253);
}

.ResetPasswordButton:hover {
  color: rgb(0, 63, 157);
}

.LogintoHomeLink, .ResetPasswordLink {
  margin: 5px;
  text-align: center;
}
