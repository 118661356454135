.MSModal {
  width: 65%;
  max-width: 80%;
  min-width: 1000px;
}

.MSModalBody {
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
}

.MSPeakInformation {
  margin: 5px;
  padding: 5px;
}

th {
  background-color: rgb(245, 245, 245);
  text-align: center;
  border: 1px solid rgb(140, 140, 140);
  font-weight: normal;
}

td {
  background-color: rgb(255, 255, 255);
  text-align: center;
  border: 1px solid rgb(140, 140, 140);
}

.MSModalHeader {
  margin: 5px;
}

.MSPlotlyDiv {
  text-align: center;
}

.fileName {
  background-color: rgb(255, 255, 255);
  min-width: 300px;
  overflow: hidden;
}

.MSModalFooter {
  width: 72%;
  min-width: 600px;
  margin: 5px auto 15px;
  padding: 2px 10%;
  font-size: 20px;
  background-color: rgb(205, 220, 235);
  border-radius: 5px;
  justify-content: left;
  text-align: left;
}

.footerTopRow {
  padding-top: 5px;
}

.MSFormControl {
  font-size: 20px;
  width: 100%;
  max-width: 300px;
  margin-right: 0px;
}

.PeakCheckBox {
  margin: 0px 4px 0px;
}

.ionForm {
  margin: 5px;
  text-align: right;
}

.selectIonForm {
  margin: 5px;
}

.selected {
  font-style: italic;
}

.threshould {
  text-align: right;
}

.dataTitle {
  background-color: rgb(230, 230, 230);
}

.CancelIcon {
  cursor: pointer;
  color: rgb(0, 0, 0);
}

.closeModalCol {
  margin: 0 0 0 auto;
  display: flex;
  justify-content: flex-end;
}

.ionValenceRow {
  padding: 2px;
  font-size: 15px;
}

.button {
  min-width: 90px;
}

.footerBottomRow {
  font-size: 15px;
}

.valenceBtn {
  width: 10px;
  font-size: 12px;
}
