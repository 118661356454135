.settingTitle {
  margin: -20px -15px;
  margin-bottom: -10px;
  font-size: 32px;
  color: rgb(45, 110, 190);
  text-shadow: 1px 2px 2px rgb(155, 150, 150);
  text-decoration: underline;
}

.settingThreshold {
  margin: 5px;
  padding: 8px;
}

.labelThreshold {
  display: flex;
  align-items: center;
}

.attentionPressEnterMessage {
  margin-top: -20px;
  margin-bottom: -10px;
  padding: 0;
  font-size: 24px;
  color: red;
}

.attentionInputDigitMessage {
  margin-top: -20px;
  margin-bottom: -10px;
  padding: 0;
  font-size: 24px;
  color: red;
}

.attentionMinusCharge {
  margin-top: -20px;
  margin-bottom: -10px;
  padding: 0;
  font-size: 24px;
  color: red;
}
