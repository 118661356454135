.DBModal {
  width: 80%;
  max-width: 80%;
  min-width: 1500px;
}

.DBModalBody {
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
}

.DBInformation {
  margin: 5px;
  padding: 5px;
}

th {
  background-color: rgb(245, 245, 245);
  text-align: center;
  border: 1px solid rgb(140, 140, 140);
  font-weight: normal;
}

td {
  background-color: rgb(255, 255, 255);
  text-align: center;
  border: 1px solid rgb(140, 140, 140);
}

.dbFileName {
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.dbGUKey {
  background-color: rgb(245, 245, 245);
  text-align: center;
}

.dbGUValue {
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.dbMSKey {
  background-color:  rgb(245, 245, 245);
  text-align: center;
}

.dbMSValue {
  background-color: rgb(255, 255, 255);
  width: 100%;
  text-align: center;
}

.closeModalCol, .dbSettingCol {
  margin: 0 0 0 auto;
  display: flex;
  justify-content: flex-end;
}

/* テーブル全体 */
.DataGrid {
  box-shadow: 0px 0px 8px rgb(128, 128, 128);
  border-radius: 3px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
}

.MuiDataGrid-columnHeaderCheckbox{
  height: 56px;
  background-color: rgb(54, 122, 248);
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 500;
}

/* テーブル行 */
.MuiDataGrid-virtualScroller{
  background-color: rgb(255, 255, 255);
}

.MuiDataGrid-columnHeaderCheckbox svg{
  color: rgb(255, 255, 255);
}

/* テーブル列名に指定したクラス */
.MuiTableColumnHeader {
  background-color: rgb(60, 120, 150);
  color: rgb(255, 255, 255);
}

/* テーブルフッター */
.MuiDataGrid-footerContainer {
  background-color: rgb(245, 245, 245);
}

/* 設定アイコン */
.SettingsIcon {
  cursor: pointer;
}

.DataTable {
  z-index: 1;
  position: absolute;
}

.dbNameMatch {
  background: rgb(185, 230, 245);
  color: rgb(0, 0, 255);
  font-weight:'bold';
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.dbNameMatch {
  padding: 0;
}

.hoverProvider {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
