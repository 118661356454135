.Footer {
  padding: 5px 5px;
  width: 100%;
  bottom: 0;
  position: fixed;
  text-align: center;
  background-color: rgb(63, 160, 255);
  color: rgb(255, 255, 255);
}

.FooterString {
  margin: 0px;
}
