.version {
  background-color:rgb(205, 225, 245);
  height:100vh;
}

.DL-button {
  font-Size:20;
  font-weight: bold;
  color:rgb(255, 255, 255);
  background-color:rgb(8, 195, 237);
  border:none;
}

.back-link-top {
  padding-left:15vh;
  color:rgb(155, 149, 149);
  font-size:25px;
  height:10vh;
}

.version-data {
  height:80vh;
  padding-top:3vh;
  padding-bottom:3vh;
}

.DataGridSetting {
  background-color:rgb(255, 255, 255);
}

.DataGridSetting {
  font-size: 25px;
}

.back-link-bottop {
  padding-left:15vh;
  color:rgb(155, 149, 149);
  font-size: 25px;
  height:10vh;
}