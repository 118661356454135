.helpCommentCompositionMS {
  font-weight: bold;
}

.helpIcon {
  margin-left: 10px;
  color: black;
}

.PopoverText {
  margin: 5px;
}
