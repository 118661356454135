.Analysis-title {
  background-color: rgb(220, 235, 255);
}

.HeaderTitleColumn {
  padding: 10px 10px 10px;
  display: inline;
  color: rgb(35, 150, 215);
}

.HeaderTitle {
  display: inline;
  color: rgb(35, 150, 215);
}

.HeaderSubTitle {
  display: inline;
  margin-left: 10px;
  color: rgb(35, 150, 215);
}

.LogoutButtonCol {
  display: flex;
  align-items: center;
}

.MoveButtonCol {
  display: flex;
  align-items: center;
  float: right;
}

.OpenManualButton, .LogoutButton {
  width: 100px;
  height: 40px;
}

.backFomulaButton {
  width: 300px;
  height: 40px;
}
