.App {
  text-align: center;
  height:100vh;
  background-color: rgb(205, 225, 245);
}

.App-header {
  background-color: rgb(205, 225, 245);
  height: 75vh;
  padding-top:25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(50px + 2vmin);
  color: rgb(35, 150, 215);
}

.App-title {
  margin: 5vh;
}

.start-link {
  color: rgb(35, 150, 215);
  font-size: 20px;
  transition: all .3s;
}

.start-link:hover {
  color: rgb(0, 90, 255);
  font-size: 20px;
  text-shadow: 1px 5px 5px rgb(155, 150, 150);
  cursor: pointer;
}

.App-link {
  color: rgb(35, 150, 215);
  font-size: 10px;
  transition: all .3s;
}

/* ホバー時に文字が浮かび上がるように設定 */
.App-link:hover {
  color: rgb(0, 90, 255);
  font-size: 10px;
  text-shadow: 1px 5px 5px rgb(155, 150, 150);
  cursor: pointer;
}

.Analysis {
  background-color: rgb(240, 240, 240);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 1400px;
}

.App .container-fluid {
  background-color: rgb(205, 225, 245);
  height:25vh;
}

.draw-glycan {
  color:rgb(0, 0, 0);
  font-size: 25px;
}

.Sheet {
  background-color: rgb(205, 220, 235);
  box-shadow: 0px 0px 8px rgb(128, 128, 128);
  border-radius: 3px;
}

.CustomHeader1 {
  font-size: 2.5rem;
  font-weight: 500;
}

.CustomHeader2 {
  font-size: 2rem;
  font-weight: 500;
}

.CustomHeader3 {
  font-size: 1.75rem;
  font-weight: 500;
}

.CustomHeader4 {
  font-size: 1.5rem;
  font-weight: 500;
}

.CustomHeader5 {
  font-size: 1.25rem;
  font-weight: 500;
}

.CustomHeader6 {
  font-size: 1rem;
  font-weight: 500;
}

.CustomSmallElement {
  font-size: 0.75rem;
}

.CustomInputForm {
  border: 1px solid rgb(130, 130, 130);
}

.CustomButton {
  color: rgb(50, 50, 50);
  background-color: rgb(250, 250, 250);
  border: solid 1px rgb(100, 100, 100);
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgb(128, 128, 128);
  font-weight: 500;
}

.CustomButton:hover {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border: solid 1px rgb(150, 150, 150);
  box-shadow: 0px 0px 4px rgb(0, 132, 255);
}

.CustomButton:disabled {
  color: rgb(150, 150, 150);
  border: solid 1px rgb(100, 100, 100);
  box-shadow: 0px 0px 4px rgb(128, 128, 128);
  background-color: rgb(200, 200, 200);
  cursor: no-drop;
}

.loaderIcon {
  color: rgb(5, 140, 255);
  margin-top: 10px;
  margin-left: 10px;
}
