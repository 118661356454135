.file {
  display: none;
}

.dropZone {
  display: inline-block;
  border: 5px solid lightcyan;
  width: calc(100% - 400px);
  color: rgb(155, 150, 150);
  padding: 10px;
  background-color: white;
}

.dropText {
  text-shadow: none;
}

.fileLabel:after {
  font-family: 'FontAwesome';
  color: rgb(0, 135, 195);
  content: "\f15b";
  font-size: 32px;
  padding: 5px;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 1000px;
}

.dragOverDisplay {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 50px;
  color: rgb(100, 100, 100);
  font-style: italic;
  margin-top: 100px;
}
