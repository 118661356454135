.Contact {
  display: grid;
  justify-items: center;
}

.ContactSuccess, .ContactFail {
  padding: 20px;
  text-align: center;
}

.FetchIconArea {
  text-align: center;
  margin: 20px;
}

.ContactColumn {
  padding: 10px;
  background-color: rgb(255, 255, 255);
  width: 500px;
}

.ContactTitle, .ContactNotice, .BackToHomeArea, .ContactSubmitButtonArea {
  text-align: center;
}

.ContactNotice {
  margin: 10px;
}

.ContactItem {
  margin: 10px 0px;
  padding: 10px;
}

.UsernameInputForm, .EmailInputForm, .SubjectInputForm {
  width: 100%;
  height: 40px;
  border-radius: 3px;
}

.MessageInputForm {
  width: 100%;
  border-radius: 3px;
}

.ContactSubmitButton {
  height: 40px;
}

.ContactSubmitButtonText {
  padding: 10px;
}
