.ImportantInfo {
  font-size: 18px;
}

.notices {
  text-align: left;
}

.ImportantInfoBody {
  padding: 10px;
}

.CsvNotice {
  color: black;
}

.explainedTerm {
  margin: 0px;
  text-decoration: underline;
}
