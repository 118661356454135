.GUFormulaArea {
  margin: 5px;
  padding: 10px;
}

.GUHeader {
  margin: 10px 10px 0px 0px;
}

.formulaGraph {
  margin-right: 10px;
  padding: 10px;
  background-color: rgb(199, 199, 199);
  border-radius: 20px;
  min-width: 380px;
}

.moveToSampleButton {
  margin: 5px;
  text-align: center;
}

.MoveSampleCustomButton {
  width: 200px;
  height: 40px;
}
