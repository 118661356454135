.software-manual {
  min-height:800px;
  min-width:1000px;
}

 .back-link-top {
  color:rgb(155, 149, 149);
  font-size:25px;
  min-height:10px;
}

.guide-body {
  min-height:600px;
  min-width:1500px;
  padding-top:2vh;
  padding-left:4vw;
}

.flow-chart {
  min-width:600px;
  padding-top:10px;
  padding-left:20px;
}

.flow-chart-box {
  border:3px solid rgb(20, 106, 186);
  background-color:rgb(218, 237, 245);
  min-height:75vh;
  min-width:63vh;
}

.home {
  margin:4vh 2vh;
  height:5vh;
  width:15vh;
  line-height:2vh; 
  justify-content:center;
  font-size: 1.5vh;
  font-weight:bold;
  color:rgb(0, 0, 0); 
  background-color:rgb(180, 199, 239);
  border:1px solid rgb(20, 106, 186);
  text-decoration:none;
}

.standard, .sample, .sign-in, .sign-up {
  margin: 4vh 2vh;
  height:5vh;
  width:15vh;
  line-height:2vh; 
  justify-content: center;
  font-size: 1.5vh;
  font-weight:bold;
  color:rgb(0, 0, 0); 
  background-color:rgb(180, 199, 239);
  border:1px solid rgb(20, 106, 186);
  white-space:pre-wrap;
  text-decoration:none;
  line-height:1em;
}

.msManual, .db, .msms {
  margin: 4vh 2vh;
  height:5vh;
  width: 15vh;
  line-height:2vh; 
  justify-content:center;
  font-size: 1.5vh; 
  font-weight:bold;
  color:rgb(0, 0, 0); 
  background-color:rgb(180, 199, 239);
  border:1px solid rgb(20, 106, 186);
  text-decoration:none;
}

.home:focus, .standard:focus, .sample:focus, .msManual:focus, .db:focus, .msms:focus, .sign-in:focus, .sign-up:focus {
  box-shadow:none;
  border:4px solid rgb(235, 110, 159);
}

.home:hover,  .standard:hover, .sample:hover, .msManual:hover, .db:hover, .msms:hover, .sign-in:hover, .sign-up:hover {
  background-color:rgb(180, 199, 239);
  color:rgb(0, 0, 0);
}

.compositionSetting, .manual-guide, .license, .used-db, .protocol, .contact {
  margin: 4vh 2vh;
  height: 5vh;
  width:12vh;
  line-height:2vh; 
  justify-content:center;
  font-size: 1.5vh; 
  font-weight:bold;
  color:rgb(0, 0, 0); 
  background-color:rgb(232, 232, 232);
  border:1px solid rgb(20, 106, 186);
  pointer-events: none;
}

.preview {
  height:40vh;
  margin-left:4vh;
}

.preview-header {
  margin-top:1vh;
  height:30px;
  width:100px;
  flex:1;
  line-height:15px; 
  justify-content:center;
  font-size:18px;
  color:rgb(255, 255, 255); 
  background-color:rgb(20, 106, 186);
  padding:0.5em
}

.preview-box {
  border:3px solid rgb(20, 106, 186);
  background-color:rgb(214, 214, 214);
  height:33vh;
  width:60vh;
}

.image {
  height:32.2vh;
  width:59.2vh;
  justify-content:center;
  top:0;                    
  bottom:0;               
  left:0;                 
  right:0;  
  margin:auto;
}

.summary {
  height:40vh;
  margin-left:4vh;
}

.summary-header {
  margin-top:2vh;
  height:30px;
  width:100px;
  flex:1;
  line-height:15px; 
  justify-content:center;
  font-size:18px;
  color:rgb(255, 255, 255); 
  background-color:rgb(20, 106, 186);
  padding:0.5em
}

.summary-box {
  border:3px solid rgb(20, 106, 186);
  background-color:rgb(218, 237, 245);
  width:60vh;
  height:31vh;
  font-size:15px;
  color:rgb(0, 0, 0);
  white-space:pre-wrap;
  padding:0.5em;
  overflow-y:scroll;
}

.bold {
  font-weight:bold;
}

.back-link-bottop {
  color:rgb(155, 149, 149);
  font-size:25px;
}