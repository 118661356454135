.Protocol {
  padding: 10px;
  text-align: center;
  min-height: 100vh;
}

.ProtocolLinkParent {
  margin: 10px;
}

.back-link-top {
  padding-left:15vh;
  color:rgb(155, 149, 149);
  font-size:25px;
  height:10vh;
}
