.peakInformation-title {
  font-size: 30px;
  margin: 10px 0px 10px;
  font-weight: bold;
}

.peakInformation {
  padding: 1px 16px;
  margin-top: 10px;
  background-color: rgb(196, 213, 235);
  border-radius: 20px;
  min-width: 400px;
  min-height: 850px;
  height: 1000px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.peakDataRow {
  margin: 5px;
  padding: 4px;
}

.peakAreaRatio {
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 25px;
}

.peakKey {
  margin: 5px 0px 5px;
}

.searchDBParam {
  background-color: rgb(153, 181, 233);
  display: block;
  border-radius: 20px;
  padding-top: 10px;
  height: calc(100% - 400px);
}

.minusChargeKey {
  text-align: left;
  display: flex;
  align-items: center;
}

.ionType {
  font-size: 22px;
  text-align: left;
  font-weight: bold;
}

.MSInputFileRow {
  font-size: 20px;
}

.loader {
  justify-content: center;
  color: rgb(5, 140, 255);
}

.MSInputFileKey {
  display: inline;
}

.fileLabel:after {
  font-family: 'FontAwesome';
  color: rgb(0, 135, 195);
  content: "\f15b";
  padding: 5px;
  cursor: pointer;
}

.dropMSZone {
  text-align: center;
  font-size: 22px;
  color: rgb(155, 150, 150);
  background-color: white;
  border: 5px solid lightcyan;
}

.partitionOver {
  padding: 10px 40px 10px;
  display: block;
}

.partitionUnder {
  padding: 10px 40px 10px;
  display: block;
  height: calc(100% - 260px);
}

.isotopeThresholdRow {
  padding: 10px 0;
  background-color: rgb(192, 192, 248);
  border-radius: 10px;
}

.isotopeKey {
  font-size: 20px;
  font-weight: bold;
}

.inputIsotopeThreshold {
  border-style: solid;
  border-radius: 5px;
}

.displayButton {
  width: auto;
  height: 40px;
}

.DBThresholdRow {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 0;
  background-color: rgb(194, 220, 245);
  border-radius: 10px;
}

.DBTresholdKey {
  display: flex;
  align-items: center;
}

.displayButtonRow {
  margin: 10px;
  text-align: center;
}

.ToleranceKey {
  font-size: 20px;
  margin: 5px 0px 5px;
  font-weight: bold;
}

.attentionPressEnterMessage {
  margin: 5px;
  font-size: 20px;
}

.attentionInputDigitMessage {
  margin: 5px;
  font-size: 20px;
}

.attentionMinusCharge {
  margin: 5px;
  font-size: 20px;
}

.divider {
  font-size: 30px;
}

.MSTabContents {
  margin: 5px;
  padding: 8px;
}
