.draw-glycan {
    background-color:rgb(205, 225, 245);
    height:100vh;
  }
  
  .back-link-top {
    padding-left:15vh;
    color:rgb(155, 149, 149);
    font-size:25px;
    height:10vh;
  }
  
  .draw-glycan-body {
    height:80vh;
    padding-top:3vh;
    padding-bottom:3vh;
  }

  .draw-glycan-heading {
    text-align:center;
    color:rgb(155, 149, 149);
    font-size: 80px;
  }

  .glycan-input-notification {
    margin-top:10vh;
    text-align:center;
    font-size:25px;
    font-weight:normal;
  }

  .glycan-input{
    font-size:25px;
  }

  .draw-button {
    margin-top:5vh;
    margin-left:auto;
    margin-right:auto;
    font-size:25px;
    font-weight:bold;
    width:100px;
  }

  .loading {
    margin-top:1vh;
    text-align:center;  
  }

  .figure {
    width:600px;
    height:400px;
    margin-top:1vh;
    text-align:center;  
  }
  
  .back-link-bottop {
    padding-left:15vh;
    color:rgb(155, 149, 149);
    font-size:25px;
    height:10vh;
  }