.ManualContainer {
  text-align: left;
  color:rgb(0, 0, 0);
}

.LoginCustomButton {
  font-size: initial;
  width: 300px;
  height: 40px;
}

.ref {
  text-align: left;
}

.RefContents {
  padding: 10px;
}

.HomeLink {
  color:rgb(0, 0, 0);
}
