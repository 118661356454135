.modalFooter {
  width: 72%;
  min-width: 600px;
  margin: 5px auto 15px;
  padding: 0px 10%;
  font-size: 20px;
  background-color: rgb(240, 240, 240);
  border-radius: 20px;
  justify-content: left;
  text-align: left;
}

.MSMSKey {
  font-weight: bold;
}

.MSMSmodalTitleRow {
  padding: 10px 10px 0px;
  margin-left: 20px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.MSMSmodalTitle {
  color: rgb(255, 255, 255);
  font-size: 35px;
  font-weight: bold;
  text-shadow: 2px 3px 3px rgb(20, 0, 165);
  flex: 0 4 auto;
}

.MSMSmodalSelectArea {
  flex: 0 3 auto;
  display: flex;
  flex-direction: row;
  align-Items: center;
}

.SelectTitle {
  text-shadow: 1px 1px 1px rgb(20, 0, 165);
}

.SelectArea {
  /* text-shadow: 1px 1px 1px rgb(20, 0, 165); */
}

.closeModalCol {
  margin: 0 0 0 auto;
  display: flex;
  justify-content: flex-end;
  flex: 0 1 auto;
}
